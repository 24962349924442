* {
    margin: 0px;
    padding: 0px;
}

.explore{
    margin-top: 60px;
}

.explore-left {
    position: absolute;
    width: 170px;
    height: 280px;
    top: 85%;
}

.explore-head {
    padding: 16px;
    box-sizing: border-box;
}

.explore-head h4 {
    color: rgba(254, 198, 63, 1);
    font: 'Roboto';
    font-weight: 500;
    font-size: 16px;
}

.explore-head h2 {
    color: rgba(24, 25, 28, 1);
    font: 'Roboto';
    font-size: 2rem;
    font-weight: 600;
}

.explore-tabs-buttons {
    text-align: center;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    box-sizing: border-box;
}

.explore-tab-btn {
    background-color: transparent;
    /* width: 120px; */
    padding: 10px 20px;
    width: 100%;
    max-width: 250px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 40px;
    text-align: center;
    color: rgba(43, 42, 41, 1);
    font: 'Roboto';
    font-weight: 500;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    outline: none;
}

.explore-tabs-buttons button.active {
    background-color: rgba(254, 204, 0, 1);
}

.explore-grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* margin: 5% auto; */
    gap: 10px;
    padding: 16px;
    box-sizing: border-box;
}

.explore-grid-width {
    width: 100%;
    max-width: 150px;
    min-width: 50px;
    gap: 10px;
}

.explore-width {
    width: 100%;
}

.explore-pp {
    font: 'Roboto';
    font-weight: 400;
    color: rgba(43, 42, 41, 1);
    font-size: 1rem;
    text-align: center;
}


@media screen and (min-width:768px) and (max-width:1024px) {
    .explore-grid {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        /* margin: 5% auto; */
        gap: 10px;
        padding: 16px;
        box-sizing: border-box;
    }

    .explore-grid-width {
        width: 100%;
        max-width: 120px;
        gap: 10px;
    }

    .explore-pp {
        font: 'Roboto';
        font-weight: 400;
        color: rgba(43, 42, 41, 1);
        font-size: 0.9rem;
        text-align: center;
    }
}

@media screen and (min-width:576px) and (max-width:768px) {
    .explore-grid {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        /* margin: 5% auto; */
        gap: 10px;
        padding: 16px;
        box-sizing: border-box;
    }

    .explore-grid-width {
        width: 100%;
        max-width: 100px;
        gap: 10px;
    }

    .explore-pp {
        font: 'Roboto';
        font-weight: 400;
        color: rgba(43, 42, 41, 1);
        font-size: 0.9rem;
        text-align: center;
    }
}

@media screen and (max-width:576px) {
    .explore-grid {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        /* margin: 5% auto; */
        gap: 10px;
        padding: 16px;
        box-sizing: border-box;
    }

    .explore-grid-width {
        width: 100%;
        max-width: 70px;
        gap: 10px;
    }

    .explore-pp {
        font: 'Roboto';
        font-weight: 400;
        color: rgba(43, 42, 41, 1);
        font-size: 0.8rem;
        text-align: center;
    }
}