* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.navbar-parent {
    padding: 5px 10px;
    box-shadow: 0px 4.046px 4.046px 0px rgba(0, 0, 0, 0.25);

}

.navbar {

    /* background: greenyellow; */
    height: 70px;
    scroll-behavior: smooth;
    /* background-color: rgba(255, 255, 255, 1); */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0px;
    box-sizing: border-box;
    overflow: hidden;
    /* height: 100%; */
}

/* div 1 css */
.div-1 {
    /* background: red; */
    width: 15%;
    /* margin: 5px 0px 0px 10px; */
}

.navbar-link-color {
    color: rgba(43, 42, 41, 1);
    text-decoration: none;
}

.squarefeet {
    width: 100%;
    max-width: 160px;
    /* height: 60px; */
}


.navbar-link-color-register {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
}


/* search box */
.search-box {
    width: 35%;
    min-width: 160px;
    /* margin-top: 4px; */
    border-radius: 5px;
}

.row {
    /* background: blue; */
    height: 40px;
    padding: 0px 10px;
    box-sizing: border-box;
    border: 1px solid #D3D3D3;
    display: flex;

    align-items: center;
    border-radius: 20px;
    /* padding: 10px 20px; */
}

.search-nav-search {
    /* flex: 1; */
    background: transparent;
    border: 0;
    outline: 0;
    font-size: 16px;
    color: #333;
    /* width: 500px; */
}

#input-box {
    /* border-radius: 20px 0px 0px 20px; */
    border-right: none;
    height: 100%;
    width: 100%;
}

.row button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    outline: 0;
    cursor: pointer;
    color: #555;
    width: 30px;
    height: 100%;
    border: none;
    /* padding: 13px 10px 14px 10px; */
}

::placeholder {
    color: #555;
}

.result-box {
    position: absolute;
    z-index: 1;
    /* bottom: 0; */
    background-color: white;
    /*  scroll: down; */
    scroll-behavior: smooth;
    width: 34.5%;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.result-box ul {
    padding: 0px 5px;
}

.result-box ul li {
    /* background: green; */
    list-style: none;
    margin: 5px 0px;
    border-radius: 3px;
    /* padding: 2px 7px; */
    border-bottom: 1px solid rgb(204, 195, 195);
    cursor: pointer;
    /* margin-left: 10px; */
}

.result-box ul li:hover {
    background: #e7e9eb;
    /* box-sizing: border-box; */
}

.nav-search-result {
    display: flex;
    align-items: center;
    gap: 10px;
}

.left-nav-img-cont-sr img {
    width: 100%;
    max-width: 50px;
}

.nav-search-name-cont{
    display: flex;
    gap: 10px;
}

.nav-sr-name {
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 600;
    font-size: 1rem;
}

.nav-sr-service {
    color: #FECC00;
    font-family: Roboto;
    font-weight: 400;
    font-size: 0.9rem;
}

.nav-search-address {
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 400;
    font-size: 1rem;
}

.div-2 {
    /* background: yellow; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    box-sizing: border-box;
}

.navbar-ul {
    display: flex;
    align-items: center;
    gap: 40px;
    /* margin-top: 5px; */
    list-style: none;
}


.navbar-ul li {
    color: rgba(43, 42, 41, 1);
    font: 'Roboto';
    font-weight: 400;
    font-size: 18px;
}

.navbar-search {
    font: 'Roboto';
    font-weight: 400;
    width: 18%;
    height: 30px;
    margin-top: 5px;
    border-radius: 5px;
}

.nav-btn {
    color: rgba(255, 255, 255, 1);
    font: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    background-color: rgba(43, 42, 41, 1);
    width: 120px;
    height: 40px;
    /* height: 36px; */
    padding: 4px;
    box-sizing: border-box;
    box-shadow: 4px 4px grey;
    /* z-index: 1; */
    transition: transform 0.3s ease;
    cursor: pointer;
}

.nav-btn:hover {
    opacity: 0.9;
    transform: scale(0.9);
}


.navbar-profile {
    width: 59px;
    height: 56px;
    cursor: pointer;
}


.line {
    width: 32px;
    background-color: rgba(254, 204, 0, 1);
    height: 5px;
    margin: 3px 3px;
}

.burger {
    display: none;
}

.burger.open .line:nth-child(1) {
    transform: rotate(45deg) translate(4px, 4px);
}

.burger.open .line:nth-child(2) {
    opacity: 0;
}

.burger.open .line:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -7px);
}

@media only screen and (max-width: 320px) {

    .burger {
        display: block;
        cursor: pointer;
        /* position: absolute;
        left: 90%;
        top: 15px; */
        opacity: 1;
        z-index: 100;
    }

    .navbar-ul.show-menu {
        opacity: 1;
        position: absolute;
        top: 0%;
        /* left: 61%; */
        right: 0%;
        width: 50%;
        height: 210px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        transition: transform 0.3s;
        display: flex;
        gap: 10px;
        flex-direction: column;
        /* overflow: auto; */
        overflow: scroll;
        overflow-x: scroll;
        overflow-y: scroll;
        -webkit-animation: mobile-slide ease-out .2s;
        -moz-animation: mobile-slide ease-out .2s;
        animation: mobile-slide ease-out .2s;
        background: rgba(255, 255, 255, 1);
        z-index: 99;
        display: block;
        padding-left: 0;
        padding: 10px;
        box-sizing: border-box;
    }

    .v-class-resp {
        opacity: 0;
        color: black;
        background-color: rgba(0, 0, 0, 0.394);
        /* width: 75%; */
    }

    #input-box {
        height: 40px;
        width: 100%;
    }

    .row button .fa-solid {
        height: 30.9px;
    }

    .squarefeet {
        width: 120px;
        height: 50px;
    }

    .navbar {
        /* background-color: rgba(255, 255, 255, 1);
        display: flex; */
        width: 100%;
    }

    .search-box {
        left: 1%;
        position: absolute;
        bottom: 14px;
        z-index: 99;
        width: 98%;

    }

    .navbar-ul {
        position: absolute;
        display: flex;
        align-items: center;
        /* transition: all 0.7s ease-out; */
    }

    .navbar-ul li {
        /* margin-top: 30px !important; */
        margin: 20px 0px;
        font-size: 16px;
    }


    .navbar-profile {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 12px;
        right: 14%;
    }

    .navbar-parent {
        height: 120px;
        position: relative;
    }

    .result-box {
        width: 100%;
    }

    .nav-btn {
        width: 90px;
        padding: 2px;
        margin-right: 10px;
        font-size: 12px;
        font-weight: 400;
    }
}

@media (min-width: 321px) and (max-width: 412px) {
    .burger {
        display: block;
        cursor: pointer;
        /* position: absolute;
        left: 90%;
        top: 15px; */
        opacity: 1;
        z-index: 100;
    }

    .navbar-ul.show-menu {
        opacity: 1;
        position: absolute;
        top: 0%;
        /* left: 61%; */
        right: 0%;
        width: 50%;
        height: 210px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        transition: transform 0.3s;
        display: flex;
        gap: 10px;
        flex-direction: column;
        /* overflow: auto; */
        overflow: scroll;
        overflow-x: scroll;
        overflow-y: scroll;
        -webkit-animation: mobile-slide ease-out .2s;
        -moz-animation: mobile-slide ease-out .2s;
        animation: mobile-slide ease-out .2s;
        background: rgba(255, 255, 255, 1);
        z-index: 99;
        display: block;
        padding-left: 0;
        padding: 10px;
        box-sizing: border-box;
    }

    .v-class-resp {
        opacity: 0;
        color: black;
        background-color: rgba(0, 0, 0, 0.394);
        /* width: 75%; */
    }

    #input-box {
        height: 40px;
        width: 100%;
    }

    .row button .fa-solid {
        height: 30.9px;
    }

    .squarefeet {
        width: 120px;
        height: 50px;
    }

    .navbar {
        /* background-color: rgba(255, 255, 255, 1);
        display: flex; */
        width: 100%;
    }

    .search-box {
        left: 1%;
        position: absolute;
        bottom: 14px;
        z-index: 99;
        width: 98%;

    }

    .navbar-ul {
        position: absolute;
        display: flex;
        align-items: center;
        /* transition: all 0.7s ease-out; */
    }

    .navbar-ul li {
        /* margin-top: 30px !important; */
        margin: 20px 0px;
        font-size: 16px;
    }


    .navbar-profile {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 12px;
        right: 14%;
    }

    .navbar-parent {
        height: 120px;
        position: relative;
    }

    .nav-btn {
        width: 90px;
        padding: 2px;
        margin-right: 10px;
        font-size: 12px;
        font-weight: 400;
    }

    .result-box {
        width: 100%;
    }
}

@media (min-width: 413px) and (max-width: 576px) {
    .burger {
        display: block;
        cursor: pointer;
        /* position: absolute;
        left: 90%;
        top: 15px; */
        opacity: 1;
        z-index: 100;
    }

    .navbar-ul.show-menu {
        opacity: 1;
        position: absolute;
        top: 0%;
        /* left: 61%; */
        right: 0%;
        width: 50%;
        height: 210px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        transition: transform 0.3s;
        display: flex;
        gap: 10px;
        flex-direction: column;
        /* overflow: auto; */
        overflow: scroll;
        overflow-x: scroll;
        overflow-y: scroll;
        -webkit-animation: mobile-slide ease-out .2s;
        -moz-animation: mobile-slide ease-out .2s;
        animation: mobile-slide ease-out .2s;
        background: rgba(255, 255, 255, 1);
        z-index: 99;
        display: block;
        padding-left: 0;
        padding: 10px;
        box-sizing: border-box;
    }

    .v-class-resp {
        opacity: 0;
        color: black;
        background-color: rgba(0, 0, 0, 0.394);
        /* width: 75%; */
    }


    #input-box {
        height: 40px;
        width: 100%;
    }

    .row button .fa-solid {
        height: 30.9px;
    }

    .squarefeet {
        width: 130px;
    }

    .navbar {
        width: 100%;
    }

    .search-box {
        left: 1%;
        position: absolute;
        bottom: 14px;
        z-index: 99;
        width: 98%;

    }

    .navbar-ul {
        position: absolute;
        display: flex;
        align-items: center;
    }

    .navbar-ul li {
        margin: 20px 0px;
        font-size: 16px;
    }

    .navbar-search {
        width: 13px;
        height: 13px;
        margin: 15px 0px 0px 0px;
    }

    .navbar-profile {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 12px;
        right: 14%;
    }

    .navbar-parent {
        height: 130px;
        position: relative;
    }

    .nav-btn {
        width: 110px;
        padding: 2px;
        font-size: 16px;
        font-weight: 400;

    }

    .result-box {
        width: 100%;
    }
}

@media (min-width: 577px) and (max-width: 810px) {
    .navbar-parent {
        height: 130px;
        position: relative;
    }

    .div-2 {
        gap: 10px;
    }

    .navbar-ul {
        gap: 15px;
    }

    .navbar-ul li {
        font-size: 16px;
    }

    .squarefeet {
        width: 120px;
    }

    .search-box {
        left: 1%;
        position: absolute;
        bottom: 18px;
        z-index: 99;
        width: 98%;

    }

    .row {
        height: 40px;
    }

    .nav-btn {
        width: 110px;
        padding: 2px;
        font-size: 16px;
        font-weight: 400;
    }

    .search-nav-search {
        font-size: 16px;
    }

    .result-box {
        width: 100%;
    }

}

@media (min-width: 811px) and (max-width: 886px) {

    .div-2 {
        gap: 6px;
    }

    .div-2 ul {
        gap: 15px;
    }

    .div-2 ul li {

        font-size: 16px;
    }

    .nav-btn {
        width: 110px;
    }
}

@media (min-width: 887px) and (max-width: 1024px) {

    .div-2 {
        gap: 8px;
    }

    .div-2 ul {
        gap: 20px;
    }

    .div-2 ul li {

        font-size: 16px;
    }

    .nav-btn {
        width: 120px;
    }

}