.send-enquery-form {
    background: #FFFFFF;
}

.se-popup-heading h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 500;
    margin-bottom: 16px;
}

.sep-text-field {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 16px;
}

.sep-text-field input {
    padding: 0 10px;
    box-sizing: border-box;
    outline: none;
    border-radius: 5px;
    background: #F2F2F2;
    border: none;
    width: 100%;
    height: 45px;
    font-size: 1rem;
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 500;
}

.sep-textarea textarea {
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    border-radius: 5px;
    background: #F2F2F2;
    border: none;
    width: 100%;
    height: 80px;
    font-size: 1rem;
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 500;
    resize: vertical;
}

.sep-send-btn {
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 500;
    font-size: 1rem;
    border: 1px solid rgba(254, 204, 0, 1);
    background-color: rgba(254, 204, 0, 1);
    box-shadow: 3px 3px gray;
    width: 120px;
    height: 45px;
    cursor: pointer;
    margin-top: 16px;
}

.sep-send-btn:hover {
    opacity: 0.7;
}

@media screen and (max-width: 567px) {
    .sep-text-field {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
        margin-bottom: 16px;
    }
}