* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: Roboto;
}

.user-register {
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 16px;
  box-sizing: border-box;
}

.left-ur {
  width: 50%;
}

.left-ur img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.right-ur {
  width: 50%;
  padding: 0 16px;
  box-sizing: border-box;
}

.right-top-ur {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right-top-ur img {
  width: 100%;
  max-width: 100px;
}

.ur-welcome-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 0px;
}

.ur-welcome-text h3 {
  color: #2B2A29;
  font-family: Roboto;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0;
}

.ur-tab-cont {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #F8EDDD;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 20px;
}

.ur-register-link {
  /* padding: 4px 20% 4px 20%; */
  width: 50%;
  border-radius: 15px;
  text-align: center;
  color: black;
  text-decoration: none;
  background-color: rgba(254, 204, 0, 1);
  box-sizing: border-box;
  color: black;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ur-supplier-link {
  width: 50%;
  /* padding: 4px 20% 4px 20%; */
  text-decoration: none;
  color: black;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ur-form-field {
  margin: 16px 0;
}

.ur-form-field label {
  color: #2B2A29;
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.2rem;
}

.ur-form-field input {
  height: 45px;
  width: 100%;
  outline: none;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid #595959;
  background-color: #FFFFFF;
  color: #2B2A29;
  font-family: Roboto;
  font-weight: 400;
  font-size: 1rem;
}

.bottom-ur {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  border: none;
  justify-content: space-between;
  align-items: center;
}

.bottom-ur button {
  box-sizing: border-box;
  background: #FECC00;
  border: none;
  border-radius: 20px;
  width: 100%;
  height: 45px;
  cursor: pointer;
  margin-bottom: 16px;
  color: #2B2A29;
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.2rem;
}

.bottom-ur button:hover {
  opacity: 0.7;
}

.ur-link {
  color: rgba(254, 204, 0, 1);
  text-decoration: none;
}

@media screen and (max-width:768px) {
  .user-register {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
  }

  .left-ur {
    /* height: 98vh; */
    width: 100%;
  }
  
  .left-ur img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
  
  .right-ur {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
  
  .right-top-ur {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

}
