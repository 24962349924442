*{
  margin:0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.vmaindv{
  padding:4px  60px 0px 60px;

  width: 100%;

}



.constructiondv {
  border: 1px solid rgba(211, 211, 211, 1);
  /* display: flex; */
  font-family: "Poppins", sans-serif;

  /* box-sizing: border-box; */
  padding: 9px;
  
  box-shadow: 3px 5px 9px rgb(109, 104, 104);
  border-radius: 10px;

  /* flex: 1; */

  /* display: flex; */
  /* margin-right: 10px; */
  /* width: 34%; */
  border-radius: 9px;
  /* padding-bottom: 7px; */
  /* margin-bottom: 10px; */
  /* height: 20%;*/
} 

.mmdv {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
}



.upperdv {
  /* background: rgba(254, 204, 0, 1); */
  padding: 10px 10px 10px 10px;
  border-radius: 15px ;
  border: none;
}
.photodv {
  /* margin: 0px 10px 10px 10px; */
}
.img0dv{
  width: 200px;
  height: 250px;
}

.img1dv {
  width: 100px;
  /* width: 50%; */
  height: 150px;
  border-radius: 5px 5px 5px 5px;
  /* display: flex;
  flex: 1; */
  margin-right: 10px;
}



.rightdv {
  /* position: relative; */
  padding-left: 10px;
  box-sizing: border-box;

 
}
.rightdv > h4 {
  padding-top: 15px;
}
.rightdv{
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;

}

.cont1dv {
  display: flex;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 1) ;
  /* opacity:8%; */
  /* border-opacity: 0.8; */
  border-top: none;
  border-left: none;
  border-right: none;
}

.smedv {
  margin-top: 3px;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
}
.threes{
  margin: 20px 0px 50px 0px;
}

.threes .t1, .threes .t2{
  background: 
  white;
  color: black;
  font-size: 14px;
  padding: 4px 10px 4px 10px;
  margin-right: 5px;
  border-radius: 15px;
  border: 1px solid gray;
}
.threes .t3{
  background:
  rgba(43, 42, 41, 1)
   ;
  font-size: 14px;
  padding: 4px 10px 4px 10px;
  border-radius: 15px;
  color: white;
}

.fa-solid{
  padding-right: 6px;
}

.urightdv {
  padding-top: 25px;
  
  font-size: 14px;
  font-weight: 450;
  
  /* background: rgba(255, 247, 228, 1); */
}
.aboutus{
  padding: 10px 0px 10px 0px;
  margin: 20px 0px 20px 0px;
  border: 1px solid rgba(0, 0, 0, 1);
  border-top: none;
  border-left: none;
  border-right: none;
}

.aboutus p{
  padding: 7px 0px 10px 0px;
  text-align: justify;
}








.photo5{
  display: flex;
  padding: 7px 0px 7px 0px;
}

.eder{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.btnedit{
  padding: 7px 25px 7px 25px;
  background:rgba(0, 0, 0, 1) ;
  color: white;
  border: none;
  border-radius: 2px;
}

@media (min-width:360px)and (max-width:760px){

.vmaindv{
  padding:4px  40px 0px 40px;

  width: 100%;

}

.threes{
margin: 10px 0px 20px 0px;
display: flex;
flex-direction: column;
}


.threes .t1, .threes .t2{
background: 
white;
color: black;
font-size: 14px;
padding: 7px;
margin-bottom: 5px;
border-radius: 15px;
border: 1px solid gray;
width: 75%;
}
.threes .t3{
background:
rgba(43, 42, 41, 1)
 ;
font-size: 13px;
padding: 7px;
border-radius: 15px;
color: white;
width: 80%;
}

.img0dv{
width: 150px;
height: 250px;
}

.upperdv {
/* background: rgba(254, 204, 0, 1); */
padding: 5px 5px 5px 5px;
border-radius: 15px ;
border: none;
}

.urightdv {
padding: 10px 0px 5px 0px;

font-size: 14px;
font-weight: 450;

/* background: rgba(255, 247, 228, 1); */
}


}
