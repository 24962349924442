* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.banner-cont {
    background-image: url('./banner images/Frame 29.png');
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(80vh - 64px);
    /* display: flex; */
    /* justify-content: space-around; */
    box-sizing: border-box;
    width: 100%;
    /* position: relative; */
}

.left-banner-text {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    width: 100%;
    max-width: 800px;
    height: 100%;
    padding: 10%;
    box-sizing: border-box;
}

.banner-head1 {
    color: rgba(255, 246, 246, 1);
    font-family: Roboto;
    font-weight: 700;
    font-size: 3rem;
}

.banner-head2 {
    color: rgba(254, 204, 0, 1);
    font-family: Roboto;
    font-weight: 700;
    font-size: 3rem;
    /* margin-bottom: 20px; */
}

.banner-head3 {
    color: rgba(255, 246, 246, 1);
    font-family: Roboto;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 40px;
    margin: 0;
}

.banner-btn {
    color: rgba(43, 42, 41, 1);
    background-color: rgba(254, 204, 0, 1);
    font: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    box-shadow: 2px 2px grey;
    cursor: pointer;
    width: 120px;
    height: 45px;
    min-height: 40px;
    border-radius: 5px;
    border: none;
    margin-top: 20px;
    transition: transform 0.3s ease;
}

.banner-btn:hover {
    opacity: 0.9;
    transform: translateY(-5px);
}

.bottom-banner-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 16px;
    box-sizing: border-box;
    /* position: absolute;
    bottom: -50px; */
}

.banner-search-cont {
    display: flex;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    max-width: 800px;
    border: 2px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    gap: 16px;
    padding: 16px;
    box-sizing: border-box;
    margin-top: 16px;
}

.banner-search-field {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.banner-search-field input {
    display: flex;
    align-items: center;
    color: rgba(43, 42, 41, 1);
    font: 'Roboto';
    font-weight: 400;
    font-size: 1rem;
    border: 1px solid #D3D3D3;
    border-radius: 30px;
    width: 100%;
    height: 45px;
    padding: 0 10px;
    box-sizing: border-box;
    outline: none;
}

.banner-services {
    padding: 3px;
    display: flex;
    align-items: center;
    gap: 5px;

}

.banner-services-input {
    border: none;
    padding: 5px;
    /* box-shadow: 1px 2px rgba(236, 236, 236, 1); */
    outline: none;
    font-size: 16px;
}

.banner-search {
    width: 50px;
    border-radius: 50%;
    /* height: 46px; */
    cursor: pointer;
}

@media screen and (min-width:567px) and (max-width: 1024px) {
    .banner-cont {
        background-image: url('./banner images/Frame 29.png');
        /* background-position: center; */
        background-repeat: no-repeat;
        /* background-size: cover; */
        height: calc(70vh - 64px);
        /* display: flex; */
        /* justify-content: space-around; */
        box-sizing: border-box;
        width: 100%;
        /* position: relative; */
    }

    .left-banner-text {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
        width: 100%;
        max-width: 700px;
        /* height: 100%; */
        padding: 0 10%;
        box-sizing: border-box;
    }

    .banner-head1 {
        color: rgba(255, 246, 246, 1);
        font-family: Roboto;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .banner-head2 {
        color: rgba(254, 204, 0, 1);
        font-family: Roboto;
        font-weight: 700;
        font-size: 2.5rem;
        /* margin-bottom: 20px; */
    }

    .banner-head3 {
        color: rgba(255, 246, 246, 1);
        font-family: Roboto;
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 40px;
        margin: 0;
    }

    .banner-search-cont {
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 1);
        width: 100%;
        max-width: 800px;
        box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.15);
        border-radius: 30px;
        gap: 16px;
        padding: 16px;
        box-sizing: border-box;
    }

    .bottom-banner-cont {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 16px;
        box-sizing: border-box;
    }

}

@media screen and (max-width: 567px) {
    .banner-cont {
        background-image: url('./banner images/mobile view.png');
        /* background-position: center; */
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(100vh - 64px);
        /* display: flex; */
        /* justify-content: space-around; */
        box-sizing: border-box;
        width: 100%;
    }

    .left-banner-text {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: flex-end;
        width: 100%;
        max-width: 800px;
        height: 100%;
        padding: 10%;
        box-sizing: border-box;
    }

    .banner-head1 {
        color: rgba(255, 246, 246, 1);
        font-family: Roboto;
        font-weight: 700;
        font-size: 2.5rem;
    }

    .banner-head2 {
        color: rgba(254, 204, 0, 1);
        font-family: Roboto;
        font-weight: 700;
        font-size: 2.5rem;
        /* margin-bottom: 20px; */
    }

    .banner-head3 {
        color: rgba(255, 246, 246, 1);
        font-family: Roboto;
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 40px;
        margin: 0;
    }

    .banner-search-cont {
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 1);
        width: 100%;
        max-width: 800px;
        box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.15);
        border-radius: 30px;
        gap: 16px;
        padding: 16px;
        box-sizing: border-box;
    }

    .bottom-banner-cont {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 16px;
        box-sizing: border-box;
    }

}