.cont-detail-cont {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
}
.cd-header {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}

.contractor-detail {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cd-cont {
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  border-radius: 20px;
  width: 90%;
  margin: 16px 0;
}

.top-cd {
  display: flex;
  box-sizing: border-box;
  /* border: 1px solid rgba(0, 0, 0, 1); */
  border-top: none;
  border-left: none;
  border-right: none;
}

.top-left-img-cont img {
  width: 100%;
  max-width: 200px;
  min-width: 200px;
}

.top-right-cont {
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;

}

.cd-send-enq-btn {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.cd-send-enq-btn button {
  background: #FECC00;
  width: 120px;
  height: 45px;
  border-radius: 20px;
  border: none;
  font-size: 1rem;
  color: #2B2A29;
  font-family: Roboto;
  font-weight: 400;
  cursor: pointer;
}

.cd-send-enq-btn button:hover {
  opacity: 0.7;
}

.cd-edit-btn {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.cd-edit-btn button {
  background: transparent;
  width: 100px;
  height: 45px;
  border-radius: 20px;
  border: none;
  font-size: 1rem;
  color: #2B2A29;
  font-family: Roboto;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}


.top-right-cont h4 {
  font-size: 1.2rem;
  color: #2B2A29;
  font-family: Roboto;
  font-weight: 500;
  margin: 0;
}

.top-right-cont p {
  font-size: 1rem;
  color: #8B8B8B;
  font-family: Roboto;
  font-weight: 500;
  margin: 0;
}

.cd-details {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 10px 0;
  box-sizing: border-box;
}

.cd-details .t1,
.cd-details .t2 {
  background: #2B2A29;
  color: #FFFFFF;
  font-size: 1rem;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: Roboto;
  gap: 5px;
  display: flex;
  align-items: center;
}

.cd-details .t3 {
  background: rgba(254, 204, 0, 1);
  font-size: 1rem;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: Roboto;
}

.cd-address {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 10px 0;
  box-sizing: border-box;
}

.cd-address span {
  font-size: 1rem;
  box-sizing: border-box;
  font-family: Roboto;
  color: #2B2A29;
  display: flex;
  gap: 10px;
}

.cd-aboutus {
  padding: 10px 0;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.cd-aboutus h3 {
  font-size: 1.2rem;
  font-weight: 500;
  box-sizing: border-box;
  font-family: Roboto;
  color: #2B2A29;
  margin: 0;
}

.cd-aboutus p {
  font-size: 1rem;
  font-weight: 400;
  box-sizing: border-box;
  font-family: Roboto;
  color: #2B2A29;
  margin: 0;
}

.cd-images-cont h3 {
  font-size: 1.2rem;
  font-weight: 500;
  box-sizing: border-box;
  font-family: Roboto;
  color: #2B2A29;
  margin: 0;
}

.cd-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.cd-images img {
  width: 100%;
  max-width: 200px;
}

.cd-logout-btn {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.cd-logout-btn button {
  background: #2B2A29;
  width: 120px;
  height: 45px;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  color: #FFFFFF;
  font-family: Roboto;
  font-weight: 400;
  cursor: pointer;
}

.cd-logout-btn button:hover {
  opacity: 0.7;
}

@media screen and (min-width:480px)and (max-width:768px) {

  .cd-details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 10px 0;
    box-sizing: border-box;
  }

}

@media screen and (max-width:480px) {

  .top-cd {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .top-left-img-cont img {
    width: 100%;
    max-width: none;
  }

  .top-right-cont {
    width: 100%;
    box-sizing: border-box;
    padding-left: 0;

  }

  .cd-details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 10px 0;
    box-sizing: border-box;
  }

}