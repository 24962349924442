.service-sidebar {
    padding: 16px;
    box-sizing: border-box;
}

.service-sidebar h4 {
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 600;
    font-size: 1.1rem;
    margin: 0;
    margin-bottom: 12px;
}

.cont0A {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

#message {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
    outline: none;
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 400;
    font-size: 1rem;
    border: 1px solid #2B2A29;
}

.cotbtnA {
    /* position: fixed; */
    display: flex;
    justify-content: center;

}

.cotbtnA button {
    padding: 8px 16px;
    border: none;
    color: #FFFFFF;
    background: rgba(43, 42, 41, 1);
    border-radius: 8px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 1rem;
    width: 100%;
    height: 48px;
    cursor: pointer;
}

.cotbtnA button:hover {
    opacity: 0.8;
}

.duckED {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
}

.duckED label {
    margin-left: 8px;
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 500;
    font-size: 0.9rem;
}

@media (min-width:360px) and (max-width:768px) {
    .contmainA {
        width: 100%;
        font-size: 12px;
        /* padding: 10px; */
        /* height: 50vh; */
        top: 4px;
        left: 0%;
        height: auto;
        padding: 5px;
    }

    #message {
        width: 100%;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 15px;
        margin-top: 10px;
    }

    /* .contmainA{
        position: fixed;
        width: 100%;
        /* width: 40%; */
    /* font-size: 12px;
        padding: 10px;
        background: white; *
    
       
    } */




}


@media (min-width:200px) and (max-width:358px) {

    .contmainA {
        width: 100%;
        font-size: 12px;
        padding: 10px;
        /* height: 50vh; */
    }

    #message {
        width: 100%;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 15px;
        margin-top: 10px;
    }

    .cont0A {
        display: flex;
        /* justify-content: space-between; */
        flex-direction: column;
    }

    .cont2A {
        margin-top: 15px;
    }
}