.up-sidebar {
    padding: 16px;
    box-sizing: border-box;
}

.top-ups {
    height: 85vh;
}

.ups-name-cont {
    display: flex;
    align-items: center;
    gap: 10px;
}

.ups-name-cont img {
    width: 40px;
}

.ups-name-cont span {
    font-size: 1rem;
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 400;
}

.ups-email-cont {
    margin: 16px 0;
}

.ups-email-cont span {
    font-size: 1rem;
    background-color: #2B2A29;
    color: #FFFFFF;
    font-family: Roboto;
    font-weight: 400;
    border-radius: 50px;
    padding: 10px 16px;
    box-sizing: border-box;
}

.ups-edit-profile-btn {
    border: none;
    background-color: transparent;
    font-size: 1rem;
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.ups-logout-btn button {
    font-size: 1rem;
    background-color: #2B2A29;
    color: #FFFFFF;
    font-family: Roboto;
    font-weight: 400;
    width: 120px;
    height: 45px;
    box-sizing: border-box;
    cursor: pointer;
    border: none;
}

.ups-logout-btn button:hover {
    opacity: 0.7;
}


/* edit profile container  */
.ups-edit-profile-content {
    padding: 16px;
    box-sizing: border-box;
    min-width: 200px;
}

.ups-edit-form-cont {
    display: flex;
    flex-direction: column;
    height: 80vh;
}

.ups-edit-form-cont label {
    display: flex;
    flex-direction: column;
    color: #2B2A29;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}

.ups-edit-form-cont input {
    border-radius: 30px;
    border: 1px solid #2B2A29;
    background: #FFF;
    color: #ACACAC;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    outline: none;
    height: 45px;
    margin-bottom: 15px;
    padding: 0 10px;
    box-sizing: border-box;
}

.ups-save-btn {
    font-size: 1rem;
    background-color: #FECC00;
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 400;
    width: 100%;
    height: 45px;
    border-radius: 30px;
    box-sizing: border-box;
    cursor: pointer;
    border: none;
}

.ups-save-btn:hover {
    opacity: 0.7;
}