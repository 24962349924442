.tandc-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tandc-head h1 {
    margin: 60px 0;
    text-align: center;
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 600;
    font-size: 2rem;
}

.bottom-tandc {
    width: 90%;
    max-width: 1024px;
}

.bottom-tandc h3 {
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 40px;
}

.tandc-detail {
    margin-bottom: 60px;
}

.tandc-detail h4 {
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 20px;
}

.tandc-detail p {
    font-size: 1rem;
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
}