* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: Roboto;
}

.forgot-password {
    display: flex;
    width: 100%;
    height: 100vh;
    padding: 16px;
    box-sizing: border-box;
}

.left-ufp {
    width: 50%;
}

.left-ufp img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.right-ufp {
    width: 50%;
    padding: 0 16px;
    box-sizing: border-box;
}

.right-top-ufp {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.right-top-ufp img {
    width: 100%;
    max-width: 100px;
}

.ufp-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0px 10px 0px;
}

.ufp-text h3 {
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 12px 0;
}

.ufp-form-field {
    margin: 16px 0;
}

.ufp-form-field label {
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 500;
    font-size: 1.2rem;
}

.ufp-form-field input {
    height: 45px;
    width: 100%;
    outline: none;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 20px;
    border: 1px solid #595959;
    background-color: #FFFFFF;
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 400;
    font-size: 1rem;
}

.ufp-btn {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    border: none;
    justify-content: space-between;
    align-items: center;
}

.ufp-btn button {
    box-sizing: border-box;
    background: #FECC00;
    border: none;
    border-radius: 20px;
    width: 100%;
    height: 45px;
    cursor: pointer;
    margin-bottom: 16px;
    color: #2B2A29;
    font-family: Roboto;
    font-weight: 400;
    font-size: 1.2rem;
}

.ufp-btn button:hover {
    opacity: 0.7;
}

.ufp-link {
    color: rgba(254, 204, 0, 1);
    text-decoration: none;
}

@media screen and (max-width:768px) {
    .forgot-password {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 16px;
        box-sizing: border-box;
    }

    .left-ufp {
        width: 100%;
    }

    .left-ufp img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
    }

    .right-ufp {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
    }

    .right-top-ufp {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}