*{
    margin:0px;
    padding: 0px;
    box-sizing: border-box;
}
.explore-suppliers-materials{
    color: rgba(43, 42, 41, 1);
    font: 'Roboto';
    font-weight: 500;
    font-size: 20px;
    border: 1px solid rgba(254, 204, 0, 1);
    background-color: rgba(254, 204, 0, 1);
    border-radius: 40px;
    padding: 15px;
    width: 290px;
    margin-top: 3%;
}
.explore-suppliers-contractors{
    margin-left: 2%;
    color: rgba(43, 42, 41, 1);
    font: 'Roboto';
    font-weight: 500;
    font-size: 20px;
    border: 1px solid rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 40px;
    padding: 1px;
    width: 260px;
}