.krinvi {
    background-color: #FFF;
    width: 100%;
    height: 100%;
    /* min-height: 30vh; */
}

.krinvi img {
    width: 100%;
    height: 100%;
    /* min-height: 30vh; */
    /* object-fit: cover; */
}