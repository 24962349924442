.top-contact {
  position: relative;
}

.top-contact img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  filter: brightness(0.3);
  position: relative;
}

.top-contact-head {
  position: absolute;
  top: 40%;
  left: 18%;
  padding: 10px;
  color: white;
}

.top-contact-head h1 span {
  color: rgba(254, 204, 0, 1)
}

.bottom-contact {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 15px;
}

.left-contact {
  width: 40%;
  min-width: 260px;
  max-width: 500px;
}

.right-contact {
  width: 40%;
  min-width: 260px;
  max-width: 500px;
}

.contact-form-head img {
  width: 60%;
  min-width: 100px;
  margin-bottom: 20px;
}

.contact-form-field {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.contact-form-field input {
  margin-bottom: 10px;
  width: 100%;
  height: 45px;
  padding: 0 10px;
  border-radius: 10px;
  border: none;
  background: rgba(243, 243, 243, 1);
  margin-bottom: 10px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  color: #2B2A29;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
}

.contact-textarea textarea {
  margin-bottom: 10px;
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  background: rgba(243, 243, 243, 1);
  margin-bottom: 10px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  color: #2B2A29;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
}

.contact-send-btn button {
  background-color: #FECC00;
  color: #2B2A29;
  border: none;
  width: 100px;
  height: 45px;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.contact-send-btn button:hover {
  opacity: 0.7;
}

.right-contact-text p {
  color: #2B2A29;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 10px;
}

.right-contact-text span {
  color: #2B2A29;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

.contact-detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  box-sizing: border-box;
}

.contact-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact-info img {
  width: 50px;
}

.contact-info div {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.contact-info span {
  color: #2B2A29;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}

.contact-info strong {
  color: #2B2A29;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}


@media screen and (max-width:568px) {
  .bottom-contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    padding: 16px;
    box-sizing: border-box;
    gap: 20px;
  }

  .left-contact {
    width: 100%;
    min-width: 250px;
  }

  .right-contact {
    width: 100%;
    min-width: 250px;
  }
}