* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: Roboto;
}

.supplier-register {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.left-sr {
  width: 50%;
}

.left-sr img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.right-sr {
  width: 50%;
  padding: 0 16px;
  box-sizing: border-box;
}

.right-top-sr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right-top-sr img {
  width: 100%;
  max-width: 100px;
}

.sr-welcome-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 0px;
}

.sr-welcome-text h3 {
  color: #2B2A29;
  font-family: Roboto;
  font-weight: 600;
  font-size: 1.1rem;
  margin: 0;
}

.sr-tab-cont {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #F8EDDD;
  padding: 6px;
  box-sizing: border-box;
  border-radius: 20px;
}

.sr-login-link {
  /* padding: 4px 20% 4px 20%; */
  width: 50%;
  border-radius: 15px;
  text-align: center;
  color: #2B2A29;
  text-decoration: none;
  background-color: rgba(254, 204, 0, 1);
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-weight: 500;
  font-size: 1rem;
}

.sr-user-link {
  width: 50%;
  /* padding: 4px 20% 4px 20%; */
  text-decoration: none;
  color: #2B2A29;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-weight: 500;
  font-size: 1rem;
}

.sr-form-field {
  margin: 10px 0;
  display: flex;
  gap: 10px;
}

.sr-form-field label {
  color: #2B2A29;
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.2rem;
  width: 100%;
}

.sr-form-field input {
  height: 45px;
  width: 100%;
  outline: none;
  padding: 0 10px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid #595959;
  background-color: #FFFFFF;
  color: #2B2A29;
  font-family: Roboto;
  font-weight: 400;
  font-size: 1rem;
  width: 100%;
}

.bottom-sr {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  border: none;
  justify-content: space-between;
  align-items: center;
}

.bottom-sr button {
  box-sizing: border-box;
  background: #FECC00;
  border: none;
  border-radius: 20px;
  width: 100%;
  height: 45px;
  cursor: pointer;
  margin-bottom: 16px;
  color: #2B2A29;
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.2rem;
}

.bottom-sr button:hover {
  opacity: 0.7;
}

.sr-link {
  color: rgba(254, 204, 0, 1);
  text-decoration: none;
}


.Bhrl3 {
  display: flex;
}

.honeservl3 {
  display: flex;
  gap: 10px;
}

.dragerl3 {
  height: 130px;
  width: 100%;
  background: #F3F3F3;
  border-radius: 12px 12px 0px 0px;
}

.wiii {
  width: 100%;
  height: 130px;
  /* height: 100%; */
  border-radius: 20px 20px 0 0;
}

.pniml3 {
  width: 200px;
  margin: 18px 0px 8px 15px;
}

.upload-inputl3 {
  display: block;
  width: 100%;
  background: rgba(243, 243, 243, 1);
  border-radius: 12px 12px 0px 0px;
  box-sizing: border-box;
}

.pniml3 p {
  font-size: small;
  background: rgba(43, 42, 41, 1);
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 12px 12px;
  padding: 5px;
  box-sizing: border-box;
  color: #FFFFFF;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
}

@media screen and (min-width: 576px) and (max-width:768px) {

  .supplier-register {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
  }

  .left-sr {
    width: 100%;
  }

  .left-sr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  .right-sr {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }

}

@media screen and (max-width:576px) {

  .supplier-register {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
  }

  .left-sr {
    width: 100%;
  }

  .left-sr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  .right-sr {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .Bhrl3 {
    display: flex;
    flex-direction: column-reverse;
  }

}